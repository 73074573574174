import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {Button} from '../../components/button';
import {WillAnimate} from '../../components/content_animate';
import {Cta} from '../../components/cta';
import {Layout} from '../../components/layout';
import {NumberHero} from '../../components/number_hero';
import {ScrollTo} from '../../components/scroll_to';

const locale = 'en';

export default ({data}: any) => (
    <Layout
        title="Our approach when developing software products"
        description="Our software product development agency works closely with clients in a flexible and iterative process. In this way we develop beautiful software products together."
        ogImage="approach-en.png"
        translatedPagePath="/nl/werkwijze/"
        locale={locale}>
        <WillAnimate as="div" name="hero" id="hero" className="hero hero--page">
            <div className="hero__content-wrapper">
                <div className="hero__content">
                    <h2>
                        At Label305 we work closely with our client in a <span className="highlight">flexible</span> and{' '}
                        <span className="highlight">iterative process</span>. We work on the basis of a{' '}
                        <span className="highlight">product vision</span> and cover risks properly. This enables us to
                        develop quality software products together.
                    </h2>
                </div>
            </div>

            <NumberHero location="left" />

            <ScrollTo className="hero__scroll" target="/en/approach/#from-hero" position="start" />
        </WillAnimate>

        <section className="section">
            <WillAnimate as="article" id="from-hero" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Iterative <br /> collective <br /> process
                        </h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            For us, software product development is a process from establishing a vision right up to
                            capability transfer. When developing a new software product, we go through the entire
                            process from start to finish together.
                        </p>
                        <p>
                            During development, the client has an important task&mdash;taking on the role of{' '}
                            <span className="highlight">product owner</span>. The product owner is closely involved in
                            the develop&shy;ment process, to ensure we always keep the right focus.
                        </p>
                        <p>
                            The product belongs to the client, now and in the future. We transfer the{' '}
                            <span className="highlight">intellectual property</span> rights automatically.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <div className="workflow-summary">
                <ul className="workflow-summary__items">
                    <li className="summary-item">
                        <ScrollTo target="/en/approach/#item-1">1. Kickoff</ScrollTo>
                    </li>
                    <li className="summary-item">
                        <ScrollTo target="/en/approach/#item-2">2. Prototype</ScrollTo>
                    </li>
                    <li className="summary-item summary-item--split-up">
                        <ScrollTo target="/en/approach/#item-3">3. Product realisation</ScrollTo>
                    </li>
                    <li className="summary-item summary-item--split-back">
                        <ul>
                            <li>
                                <ScrollTo target="/en/approach/#item-4a">4a. Serving software</ScrollTo>
                            </li>
                            <li>
                                <ScrollTo target="/en/approach/#item-4b">4b. Continuous development</ScrollTo>
                            </li>
                        </ul>
                    </li>
                    <li className="summary-item">
                        <ScrollTo target="/en/approach/#item-5">5. Development transition</ScrollTo>
                    </li>
                </ul>
            </div>

            <article className="workflow">
                <div className="container">
                    <WillAnimate as="div" className="workflow-item workflow-item--start" id="item-1" />

                    <WillAnimate as="div" className="workflow-item">
                        <div className="workflow-item__line" />
                        <div className="workflow-item__title">
                            <div className="title__number">1</div>
                            <h2>Get us on board with your product vision</h2>
                        </div>
                        <div className="workflow-item__copy">
                            <p className="p-lead">
                                The process starts with discover&shy;ing what we want to create. During the kick-off
                                session we decide together on the essential require&shy;ments for the software product,
                                based on the <span className="highlight">product vision</span>.
                            </p>
                            <ul className="list">
                                <li className="ion-md-arrow-dropright">Getting round the table together</li>
                                <li className="ion-md-arrow-dropright">&#189; &ndash; 2 days</li>
                                <li className="ion-md-arrow-dropright">1 &ndash; 3 specialists</li>
                            </ul>
                        </div>

                        <div className="workflow-item__buttons">
                            <Button
                                url="/en/approach/kick-off/"
                                name="Find out more about the kick-off"
                                title="Approach step 1. Kick-off"
                            />
                        </div>
                    </WillAnimate>

                    <WillAnimate as="div" className="workflow-item" id="item-2">
                        <div className="workflow-item__line" />
                        <div className="workflow-item__title">
                            <div className="title__number">2</div>
                            <h2>Concept- and technology validation</h2>
                        </div>
                        <div className="workflow-item__copy">
                            <p className="p-lead">
                                The key to successful reali&shy;sation of a new software product is generally the{' '}
                                <span className="highlight">validation</span> of a concept or technology. That’s why we
                                start by making a <span className="highlight">prototype</span>.
                            </p>
                            <ul className="list">
                                <li className="ion-md-arrow-dropright">Iterations of 1 week</li>
                                <li className="ion-md-arrow-dropright">2 &ndash; 6 weeks</li>
                                <li className="ion-md-arrow-dropright">1 &ndash; 3 specialists</li>
                            </ul>
                        </div>
                        <div className="workflow-item__buttons">
                            <Button
                                url="/en/approach/prototyping/"
                                name="Find out more about the prototype"
                                title="Approach step 2. Prototyping"
                            />
                        </div>
                    </WillAnimate>

                    <WillAnimate as="div" className="workflow-item" id="item-3">
                        <div className="workflow-item__line" />
                        <div className="workflow-item__title">
                            <div className="title__number">3</div>
                            <h2>Product realisation</h2>
                        </div>
                        <div className="workflow-item__copy">
                            <p className="p-lead">
                                We go on to realise the product, starting with a clean slate. Using the knowledge and
                                plans from the kick-off and concept phases, together we design and develop the{' '}
                                <span className="highlight">first viable version</span> of the software
                                product&mdash;all the way from scratch in a relatively short period.
                            </p>
                            <ul className="list">
                                <li className="ion-md-arrow-dropright">Iterations of 1 &ndash; 2 weeks</li>
                                <li className="ion-md-arrow-dropright">Interim milestones</li>
                                <li className="ion-md-arrow-dropright">4 &ndash; 12 months</li>
                                <li className="ion-md-arrow-dropright">2 &ndash; 4 specialists</li>
                            </ul>
                        </div>
                        <div className="workflow-item__buttons">
                            <Button
                                url="/en/approach/product-realisation/"
                                name="Find out more about realisation"
                                title="Approach step 3. Product realisation"
                            />
                        </div>
                    </WillAnimate>

                    <WillAnimate as="div" className="workflow-item workflow-item--left" id="item-4a">
                        <div className="workflow-item__line" />
                        <div className="workflow-item__title">
                            <div className="title__number">4a</div>
                            <h2>Serving software reliably in production</h2>
                        </div>
                        <div className="workflow-item__copy">
                            <p className="p-lead">
                                Once a software product has been launched it’s essential to ensure{' '}
                                <span className="highlight">continuous, secure and correct operation</span>, which
                                doesn’t happen by itself. So we decide together what level of service and maintenance is
                                most appro&shy;priate for the product.
                            </p>
                            <ul className="list">
                                <li className="ion-md-arrow-dropright">Ongoing maintenance</li>
                                <li className="ion-md-arrow-dropright">Proactive intervention</li>
                                <li className="ion-md-arrow-dropright">Continuous monitoring</li>
                                <li className="ion-md-arrow-dropright">Uptime guaranteed</li>
                            </ul>
                        </div>
                        <div className="workflow-item__buttons">
                            <Button
                                url="/en/approach/service/"
                                name="Find out more about service"
                                title="Approach step 4A. Serving software"
                            />
                        </div>
                    </WillAnimate>

                    <WillAnimate as="div" className="workflow-item workflow-item--right" id="item-4b">
                        <div className="workflow-item__line" />
                        <div className="workflow-item__title">
                            <div className="title__number">4b</div>
                            <h2>Continuous improvement and development</h2>
                        </div>
                        <div className="workflow-item__copy">
                            <p className="p-lead">
                                A software product is never finished. We need to keep up the momentum and involvement
                                and prevent stagnation. That’s why we agree on arrangements for{' '}
                                <span className="highlight">periodic development</span>.
                            </p>
                            <ul className="list">
                                <li className="ion-md-arrow-dropright">Iterations of 1 &ndash; 2 weeks</li>
                                <li className="ion-md-arrow-dropright">At least 1 iteration per month</li>
                                <li className="ion-md-arrow-dropright">1 &ndash; 4 specialists</li>
                            </ul>
                        </div>
                        <div className="workflow-item__buttons">
                            <Button
                                url="/en/approach/continuous-development/"
                                name="Find out more about continuous development"
                                title="Approach step 4B. Continuous development"
                            />
                        </div>
                    </WillAnimate>

                    <WillAnimate as="div" className="workflow-item workflow-item--last" id="item-5">
                        <div className="workflow-item__line" />
                        <div className="workflow-item__title">
                            <div className="title__number">5</div>
                            <h2>From product to self-sufficient software business</h2>
                        </div>
                        <div className="workflow-item__copy">
                            <p className="p-lead">
                                Products we develop need to be able to <span className="highlight">outgrow</span> our
                                services. That’s why we help to set up the client’s own internal product development
                                team.
                            </p>
                            <ul className="list">
                                <li className="ion-md-arrow-dropright">Entirely optional</li>
                                <li className="ion-md-arrow-dropright">Capability transfer</li>
                                <li className="ion-md-arrow-dropright">Minimum of 3 candidates</li>
                                <li className="ion-md-arrow-dropright">At least 3 months per candidate</li>
                            </ul>
                        </div>
                        <div className="workflow-item__buttons">
                            <Button
                                url="/en/approach/capability-transfer/"
                                name="Find out more about capability transfer"
                                title="Approach step 5. capability transfer"
                            />
                        </div>
                    </WillAnimate>
                </div>
            </article>

            <WillAnimate as="article" className="content">
                <div className="image image--text-left">
                    <div className="image__info">
                        <h2>
                            Staying fully informed about the vision and the plans for the software product at all times
                            is <span className="highlight">essential</span>. We work closely with our clients and build
                            long-term relationships.
                        </h2>
                    </div>
                    <div className="image__content">
                        <Img alt="Desks" fluid={data.autosociaal.childImageSharp.fluid} />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            We regularly sit down with our client’s product owner to review and refine the backlog and
                            to make new plans.
                        </span>
                    </div>
                </div>
            </WillAnimate>

            <Cta image={data.olav.childImageSharp.fixed} locale={locale} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        autosociaal: file(relativePath: {eq: "office/autosociaal.jpg"}) {
            ...fluid1200Width
        }
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }
    }
`;
